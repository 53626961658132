<template>
  <div
    :class="['calculator-landing-page', {
      'calculator-landing-page--local': LOCAL
    }]"
  >
    <calculator />

    <p v-html="DURATION.LEGAL_TEXT" />

    <div class="navigation-buttons">
      <zg-button
        :icons="{ right: 'arrow-forward' }"
        name="next-btn"
        size="xlarge"
        type="submit"
        @click="next"
      >
        {{ content.submit }}
      </zg-button>
    </div>

    <disclaimer key="disclaimer" />
  </div>
</template>

<script>
  import { mapActions, mapGetters } from 'vuex'
  import Calculator from '../single-step/_shared/Calculator'
  import Disclaimer from '../single-step/_shared/Disclaimer.vue'

  export default {
    name: 'CalculatorLandingPage',
    components: {
      Disclaimer,
      Calculator,
      ZgButton: () => import(/* webpackChunkName: 'zc/zg-button' */
        '@zc/components/ZgButton/ZgButton')
    },
    computed: {
      ...mapGetters('content', ['getContent']),
      ...mapGetters('translations', ['getLocale']),
      ...mapGetters('form', ['getForm']),
      content () {
        return this.getContent?.application?.calculator ?? {}
      }
    },
    async serverPrefetch () {
      await this.init()
    },
    async mounted () {
      await this.init()
      this.$emit('loaded')
    },
    methods: {
      ...mapActions('cl/application', ['setLoanValues']),
      async init () {
        await this.setLoanValues()
      },
      next () {
        const url = this.BROKER
          ? this.URLS.BROKER.APPLICATION
          : this.URLS.ZMARTA.APPLICATION

        const query = `?loanAmount=${this.getForm?.global?.loanAmount?.value}&repaymentYears=${this.getForm?.global?.repaymentYears?.value}`

        window.location.href = `${url}${query}`
      }
    }
  }
</script>

<style lang="scss" scoped>
  .calculator-landing-page {
    position: relative;

    &--local {
      padding: rhythm(small);
      border-radius: radius(input);
      background-color: color(white);
      box-shadow: box-shadow();

      @include mq(medium) {
        width: rem(560px) + (rhythm(medium) * 2);
        margin: 0 auto;
        padding: rhythm(medium);
      }
    }
  }

  .navigation-buttons {
    display: flex;
    flex-direction: column;
    align-items: baseline;
    justify-content: flex-start;
    margin: 0 0 rhythm(small);

    ::v-deep .zg-button {
      width: 100%;

      @include mq(medium) {
        width: auto;
      }
    }
  }
</style>
