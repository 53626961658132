var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      class: [
        "calculator-landing-page",
        {
          "calculator-landing-page--local": _vm.LOCAL,
        },
      ],
    },
    [
      _c("calculator"),
      _vm._v(" "),
      _c("p", { domProps: { innerHTML: _vm._s(_vm.DURATION.LEGAL_TEXT) } }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "navigation-buttons" },
        [
          _c(
            "zg-button",
            {
              attrs: {
                icons: { right: "arrow-forward" },
                name: "next-btn",
                size: "xlarge",
                type: "submit",
              },
              on: { click: _vm.next },
            },
            [_vm._v("\n      " + _vm._s(_vm.content.submit) + "\n    ")]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("disclaimer", { key: "disclaimer" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }