var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      class: [
        "calculator",
        {
          "calculator--local": _vm.LOCAL,
        },
      ],
    },
    [_c("calculator-landing-page")],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }