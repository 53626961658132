<template>
  <div
    :class="['calculator', {
      'calculator--local': LOCAL
    }]"
  >
    <calculator-landing-page />
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'
  import CalculatorLandingPage from '../../application/cl/calculator-landing/CalculatorLandingPage'

  export default {
    name: 'Calculator',
    components: {
      CalculatorLandingPage
    },
    computed: {
      ...mapGetters('router', ['getRoute', 'getQuery']),
      onBeigeBackground () {
        return this.getQuery?.['on-background'] === 'beige'
      }
    },
    async mounted () {
      await this.$emit('loaded')
    }
  }
</script>

<style lang="scss" scoped>
  .calculator {
    width: 100%;
    min-height: auto;

    &--local {
      min-height: 100vh;
      background-color: color(beige);
      padding: rhythm(large) 0;
    }
  }
</style>
